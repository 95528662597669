import { tw } from '@/utils/tw';
import BaseButtonTheme from 'base/components/Button/theme';

const { Icon } = BaseButtonTheme;

const Button = tw.theme({
  extend: BaseButtonTheme,
  base: [],
  variants: {
    colors: {
      primary: [
        'bg-black',
        'border-gray-700',
        'hover:bg-gray-700',
        'active:bg-gray-800',
        'focus:ring-secondary-200',
        'text-white',
      ],
      secondary: [
        'bg-gray-100',
        'border-gray-200',
        'hover:bg-gray-200',
        'active:bg-gray-300',
        'focus:ring-secondary-200',
        'text-black',
      ],
    },
    size: {
      small: ['text-headline-xs'],
      medium: ['text-headline-sm'],
      large: ['text-headline-sm'],
    },
  },
});

export default Object.assign(Button, { Icon });
