import { tw } from '@/utils/tw';
import BaseNavigationTheme from 'base/components/Navigation/theme';

const Button = tw.theme({
  extend: BaseNavigationTheme.Button,
  variants: {
    size: {
      small: 'border-0',
    },
  },
});

const BaseLinkVariants = BaseNavigationTheme.Link.variants;

const Link = tw.theme({
  variants: {
    colors: {
      bar: ['text-white'],
      drawer: ['text-black'],
    },
    size: {
      ...BaseLinkVariants.size,
      drawer: ['py-2'],
    },
    variant: {
      bar: ['text-headline-xs', 'inline-block'],
      drawerPrimary: ['text-headline-sm', 'block'],
      drawerSecondary: ['text-headline-sm', '!font-normal', 'block', 'py-3', 'sm:py-2', 'last:pb-6', 'sm:last:pb-2'],
    },
  },
});

export default Object.assign({}, { Button, Link });
