import { tw } from '@/utils/tw';
import BaseAuthorDetailsTheme from 'base/components/AuthorDetails/theme';

const { SoMe } = BaseAuthorDetailsTheme;

const Image = tw.theme({
  extend: BaseAuthorDetailsTheme.Image,
  variants: {
    variant: {
      default: {
        base: ['w-25', 'sm:w-25', 'h-25', 'data-[fill]:aspect-square'],
      },
    },
  },
});

const AuthorDetails = tw.theme({
  extend: BaseAuthorDetailsTheme,
  variants: {
    variant: {
      default: {
        base: ['bg-gray-100', 'max-w-none', 'p-3', 'gap-x-6', 'mt-6', 'sm:mt-10'],
        caption: ['prose', 'prose-p:m-0', 'prose-a:font-bold', 'prose-author-details'],
        description: ['prose', 'prose-author-details', 'mt-3', 'text-inherit'],
        email: ['text-primary-700', 'text-headline-xs', 'sm:text-headline-sm'],
        headline: ['text-headline-sm', 'sm:text-headline-lg', 'mb-2', 'mt-3', 'sm:mt-0'],
      },
    },
  },
});

export default Object.assign(AuthorDetails, { Image, SoMe });
