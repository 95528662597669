import { tw } from '@/utils/tw';
import BaseRecommendedArticlesTheme from 'base/components/RecommendedArticles/theme';

const Skeleton = tw.theme({
  extend: BaseRecommendedArticlesTheme.Skeleton,
  base: ['h-[188px]'],
});

const ArticleTeaser = tw.theme({
  extend: BaseRecommendedArticlesTheme.ArticleTeaser,
  slots: {
    base: ['grid-cols-[130px_1fr]'],
    description: ['hidden'],
    caption: ['hidden'],
    headline: [],
    group: ['!p-0', 'mt-0', 'sm:mt-2'],
  },
  variants: {
    variant: {
      vertical: {
        headline: ['text-base', 'leading-5'],
      },
    },
  },
});

const RecommendedArticles = tw.theme({
  extend: BaseRecommendedArticlesTheme,
  slots: {
    base: ['relative', 'bg-white', 'p-3', 'md:py-3'],
    headline: ['block', 'uppercase', 'mb-2', 'text-headline-sm', 'sm:text-headline-md'],
    list: ['gap-3'],
  },
});

export default Object.assign(RecommendedArticles, { ArticleTeaser, Skeleton });
