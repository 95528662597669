import { BrandOcelotConfig } from '@/types/ocelotConfig';

const HantConfig: BrandOcelotConfig = {
  search: {
    googleCustomSearch: {
      id: '008262495618786222540:-zculetqj7u',
    },
    placeholderImage: 'https://files.hant.se/uploads/2019/02/hant_logo_new.svg',
  },
  analytics: {
    brandName: 'hant',
    google: {
      measurement_id: 'G-FRK7B5WRY7',
      dimensions: {
        page_id: '1',
        mobile_website: '2',
      },
      viewId: 141885915,
      hostname: 'www.hant.se',
    },
    parsely: {
      siteId: 'hant.se',
    },
    sifo: {
      propertyId: '2cd85b8906754bf1b4501f15d18158b1',
    },
    tealium: {
      account: 'aller-media-sweden',
      profile: 'main',
      environment: 'prod',
    },
    avo: {
      apiKey: 'blcuGqEsX2nT6VJtqDMv',
    },
    funnelRelay: {
      customerId: 'aller_e7bce_hant',
      scriptSource: 'cdn-magiclinks.trackonomics.net/client/static/v2/aller_e7bce_hant.js',
    },
    tags: ['funnelRelay', 'engagedTime', 'scrollDepthTracker', 'csMatchingKey', 'loggedInId', 'metadata', 'webVitals'],
    jwplayer: true,
  },
  features: {
    strossle: {
      enabled: true,
      id: '8559bf01-17e9-4960-9b2b-f018efb08e58',
    },
    infinityScrollArticle: {
      enabled: true,
    },
    infinityScrollFront: {
      title: 'FRÅN FÖRSTASIDAN',
    },
    direktBanner: {
      enabled: true,
      excludedLabradorPageTypes: ['front', 'etikett'],
    },
    keesingGames: {
      enabled: true,
      customerId: 'hant',
      scriptPath: 'https://web.keesing.com/pub/bundle-loader/bundle-loader.js',
      stylesPath: 'https://web.keesing.com/pub/config/hant/css/custom_client.css',
      frontPagePathname: '/korsord',
    },
    highlightedArticles: {
      excludeUrls: ['/om-oss', '/kontakta-oss', '/om-cookies', '/hant-app', '/tipsa-oss', '/nyhetsbrev', '/alla-amnen'],
    },
    direkt: {
      enabled: true,
      id: 'ifr-direkt-3e2be4a4-56e6-4998-9a07-e91af5c27d35',
      aId: '3e2be4a4-56e6-4998-9a07-e91af5c27d35',
      headline: 'Hänt',
      description:
        'Följ vår direktrapportering med nyheter om kändisar och kungligheter du älskar. Läs det senaste från nöjesvärlden här!',
      seoTitle: 'Hänt Direkt – snabba kändisnyheter',
      seoDescription:
        'Följ Hänts direktrapportering med snabba nyheter från nöjesvärlden. Vi uppdaterar dig löpande om kändisarna och kungligheterna du älskar!',
    },
    trendingMenu: {
      label: 'LÄS MER:',
      tagPrefixes: ['nytt!'],
    },
  },
  pages: {
    direkt: {
      enabled: true,
    },
  },
  seo: {
    schemaMarkup: {
      name: 'Hänt',
      foundingDate: '1964-09-01',
      alternateName: ['Hänt i veckan', 'Hänt Extra'],
      sameAs: [
        'https://sv.wikipedia.org/wiki/H%C3%A4nt.se',
        'https://sv.wikipedia.org/wiki/H%C3%A4nt_i_Veckan',
        'https://sv.wikipedia.org/wiki/H%C3%A4nt_Extra',
        'https://www.wikidata.org/wiki/Q10531561',
        'https://www.youtube.com/@hant-tv3867',
        'https://www.facebook.com/hant.aller/?locale=sv_SE',
        'https://x.com/hant_extra',
        'https://x.com/Hant_SE',
        'https://www.instagram.com/hant.se',
        'https://www.tiktok.com/@hant.se',
        'https://news.google.com/publications/CAAqBwgKMPT9lQswx6GtAw?hl=sv&gl=SE&ceid=SE%3Asv',
      ],
      publishingPrinciples: 'https://www.hant.se/redaktionell-policy',
      verificationFactCheckingPolicy: 'https://www.hant.se/redaktionell-policy#:~:text=K%C3%A4llkontroll,handlar%20om.',
      correctionsPolicy: 'https://www.hant.se/redaktionell-policy#:~:text=Korrigeringar,ansvarige%20utgivare',
      unnamedSourcesPolicy:
        'https://www.hant.se/redaktionell-policy#:~:text=S%C3%A5%20behandlar%20vi,%20m%C3%A5lsmans%20godk%C3%A4nnande.',
      missionCoveragePrioritesPolicy: 'https://www.hant.se/redaktionell-policy.',
      webPages: [
        '/om-oss',
        '/kontakta-oss',
        '/om-cookies',
        '/alla-amnen',
        '/nyhetsbrev',
        '/tipsa-oss',
        '/lasarpanelen',
        '/hant-app',
        '/redaktionell-policy',
        '/vara-skribenter',
      ],
    },
  },
  mobileApp: {
    enabled: true,
    meta: {
      'al:android:url': 'https://www.hant.se?utm_medium=social&utm_campaign=deeplink&utm_source=facebook',
      'al:android:app_name': 'Hänt',
      'al:android:package': 'se.aller.hant',
      'al:ios:url': 'https://www.hant.se?utm_medium=social&utm_campaign=deeplink&utm_source=facebook',
      'al:ios:app_store_id': '1247376163',
      'al:ios:app_name': 'Hänt',
      'al:web:should_fallback': 'true',
    },
  },
  ads: {
    exceptionPaths: {
      '^korsord$': {
        aboveBody: true,
        extraStartCount: 5000,
        useHeights: false,
      },
      '^korsord/\\S+$': {
        extraStartCount: 5000,
        useHeights: false,
      },
    },
    video: {
      clickToPlay: 'DZo5Gx0b',
      autoPlay: 'DrX1nVjT',
    },
  },
  cmp: {
    onetrust_id: '2a74748e-f334-415c-8e61-5b990c45ebe2',
  },
  common: {
    redirects: [
      {
        from: '^korsord/',
        to: 'korsord',
      },
    ],
  },
};

export default HantConfig;
